import environment from '../../../config/environment.json';

let GlobalConfig = {
    backendBaseUrl:        environment.backendBaseUrl,
    backendBaseApiUrl:     environment.backendBaseUrl + '/api/',
    defaultListView:       'resources/views/default/list.html',
    defaultSimpleListView: 'resources/views/default/simple-list.html',
    defaultFormView:       'resources/views/default/form.html',
    defaultButtonFormView: 'resources/views/default/button-form.html',
    generateApiUrl:        (relativeUrl) => environment.backendBaseUrl + '/api/' + relativeUrl,
};

export { GlobalConfig };
