import { DialogService }   from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { inject }          from 'aurelia-framework';
import { I18N }            from 'aurelia-i18n';

@inject(EventAggregator, I18N, DialogService)
export class BaseFormSchema {

    modelDefaults = {};

    /**
     * Constructor
     *
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     */
    constructor(eventAggregator, i18n, dialogService) {
        this.eventAggregator = eventAggregator;
        this.i18n            = i18n;
        this.dialogService   = dialogService;
    }

}
