import { inject }   from 'aurelia-framework';
import { Router }   from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import environment  from '../../../config/environment.json';

@inject(Router)
export class UnauthenticatedRouterConfig {

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }

    /**
     * Configure router
     */
    configure() {
        let appRouterConfig = (config) => {
            config.title = environment.productName;

            let routesConfigMap = [
                // ==================== login ====================
                {
                    route:    ['', 'login'],
                    name:     'login',
                    moduleId: PLATFORM.moduleName('login'),
                    nav:      false,
                    title:    'Login',
                },
                // ==================== password-resets ====================
                {
                    route:    'password-resets/request',
                    name:     'password-resets.request',
                    moduleId: PLATFORM.moduleName('password-resets/request/index'),
                    nav:      false,
                    title:    'Recuperar password',
                },
                {
                    route:    'password-resets/reset/:token',
                    name:     'password-resets.reset',
                    moduleId: PLATFORM.moduleName('password-resets/reset/index'),
                    nav:      false,
                    title:    'Recuperar password',
                },
                // ==================== maintenance ====================
                {
                    route:    ['maintenance'],
                    name:     'maintenance',
                    moduleId: PLATFORM.moduleName('maintenance'),
                    nav:      true,
                    title:    'Maintenance',
                },
            ];

            config.map(routesConfigMap);
            config.mapUnknownRoutes(() => routesConfigMap[0]);
        };

        this.router.configure(appRouterConfig);
    }

}
