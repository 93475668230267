import environment      from '../../../config/environment.json';
import { GlobalConfig } from './global-config';

let stateGenerator = () => {
    let state    = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 32; i++) {
        state += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return state;
};

let AureliaAuthConfig = {
    baseUrl:        GlobalConfig.backendBaseApiUrl,
    loginUrl:       'auth/login',
    loginRedirect:  '#/dashboard',
    logoutUrl:      'auth/logout',
    logoutRedirect: '#/',

    tokenName:   'token',
    tokenPrefix: 'aurelia',

    providers: environment.auth.providers || {},
};

for (let provider in AureliaAuthConfig.providers) {
    if (AureliaAuthConfig.providers.hasOwnProperty(provider)) {
        AureliaAuthConfig.providers[provider].state = stateGenerator;
    }
}

export { AureliaAuthConfig };
